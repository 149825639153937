import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Loader from "react-loader-spinner";

import {
  fetchUserOnboardProgress,
  onboardResetPassword,
} from "./userOnboardSlice";

import { loginThunk, statusThunk } from "../user/userSlice";

import styles from "./userOnboard.module.css";

import { ReactComponent as LogoSVG } from "../../app/assets/logo.svg";

function hasNumber(str) {
  return /\d/.test(str);
}

function hasUpperCase(str) {
  return /[A-Z]/.test(str);
}

export const SmallRedDot = () => {
  return <div className={styles.SmallRedDot}></div>;
};

export const SmallGreenDot = () => {
  return <div className={styles.SmallGreenDot}></div>;
};

export const UserOnboardPage = () => {
  const token = useSelector((state) => state.userOnboard.token);
  const status = useSelector((state) => state.userOnboard.status);
  const email = useSelector((state) => state.userOnboard.email);
  const active = useSelector((state) => state.userOnboard.active);
  const password_resetted = useSelector(
    (state) => state.userOnboard.password_resetted
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const text = "Confirm and start using Athelas Provider Portal";
  const [buttonText, setButtonText] = useState(text);

  const onPasswordChange = (e) => setPassword(e.target.value);
  const onConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchUserOnboardProgress({ token }));
    }
  });

  const handleSubmit = async () => {
    setButtonText("Loading...");
    if (password === "") {
      alert("Please provide a password");
    } else if (confirmPassword === "") {
      alert("Please confirm new password");
    } else if (password !== confirmPassword) {
      alert("Passwords doesn't match.");
    } else {
      try {
        const resultAction = await dispatch(
          onboardResetPassword({
            token,
            new_password: password,
          })
        );
        const resetResponse = unwrapResult(resultAction);
        if (resetResponse.status !== 200) {
          alert(resetResponse.message);
        } else {
          const resultAction = await dispatch(loginThunk({ email, password }));
          const loginResponse = unwrapResult(resultAction);

          const token = loginResponse.auth_token;
          dispatch(statusThunk({ token }));

          history.push("/home");
        }
      } catch {
        alert(
          "Server error occured, please contact us at support@getathelas.com"
        );
      }
    }
    setButtonText(text);
  };

  const confirmButtonDisabled = () => {
    return (
      buttonText === "Loading..." ||
      password === "" ||
      confirmPassword === "" ||
      password !== confirmPassword
    );
  };

  let content;

  if (status === "loading") {
    content = (
      <div className={styles.LoaderWrapper}>
        <Loader type="TailSpin" color="#42A1F8" />
      </div>
    );
  } else if (password_resetted || active) {
    content = (
      <>
        <div className={styles.LogoSection}>
          <LogoSVG />
        </div>
        <div>
          User has been actived already, please navigate to{" "}
          <a href="https://app.athelas.com/login">
            https://app.athelas.com/login
          </a>{" "}
          to login
        </div>
      </>
    );
  } else if (!password_resetted && !active) {
    // reset password part
    content = (
      <>
        <div className={styles.LogoSection}>
          <LogoSVG />
        </div>
        <div className={styles.Instruction}>
          Set your password to finish account setup
        </div>
        <div className={styles.PasswordContent}>
          <div className={styles.LeftTop}>
            <label className={styles.PWInputLabel}>Username</label>
            <br />
            <input
              type="text"
              placeholder="Password"
              className={styles.UsernameInput}
              value={email}
              disabled
            />
          </div>
          <div className={styles.RightTop}></div>
          <div className={styles.LeftBottom}>
            <div>
              <label className={styles.PWInputLabel}>Password</label>
              <br />
              <input
                type="password"
                placeholder="Password"
                className={styles.PasswordInput}
                value={password}
                onChange={onPasswordChange}
              />
            </div>
            <div>
              <label className={styles.PWInputLabel}>Confirm password</label>
              <br />
              <input
                type="password"
                placeholder="Password"
                className={styles.PasswordInput}
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
              />
            </div>
          </div>
          <div className={styles.RightBottom}>
            <div className={styles.RightBottomWrapper}>
              <div className={styles.PasswordStrengthText}>
                Password Strength
              </div>
              <div className={styles.CheckListItem}>
                {password.length < 10 ? <SmallRedDot /> : <SmallGreenDot />} At
                least 10 characters
              </div>
              <div className={styles.CheckListItem}>
                {!hasNumber(password) ? <SmallRedDot /> : <SmallGreenDot />} At
                least 1 number
              </div>
              <div className={styles.CheckListItem}>
                {!hasUpperCase(password) ? <SmallRedDot /> : <SmallGreenDot />}{" "}
                At least 1 uppercase character
              </div>
              <div className={styles.CheckListItem}>
                {!"!@#$%^*".split("").some(function (v) {
                  return password.indexOf(v) >= 0;
                }) ? (
                  <SmallRedDot />
                ) : (
                  <SmallGreenDot />
                )}{" "}
                At least 1 special character ( !@#$%^* )
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Footer}>
          <button
            onClick={handleSubmit}
            disabled={confirmButtonDisabled()}
            className={
              confirmButtonDisabled()
                ? styles.DisabledConfirmButton
                : styles.ConfirmButton
            }
          >
            {buttonText}
          </button>
        </div>
      </>
    );
  } else {
    content = (
      <div>
        Server error occured, please contact us at support@getathelas.com
      </div>
    );
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.MainContent}>{content}</div>
    </div>
  );
};
