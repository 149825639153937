import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../header/Header";

import documentsAPI from "./documentsAPI";
import fileDownload from "js-file-download";
import { trackEvent } from "../../utils/tracking";
import { useProdVar } from "../../utils/prodVars";

import {
  agreementStatusThunk,
  fetchClaimCollectionsThunk,
} from "./documentsSlice";

import styles from "./Documents.module.css";
import { IsoDateToFullMonthYYYY } from "../../utils/date";
import { ReactComponent as ProfileSVG } from "./Profile.svg";
import ZipPNG from "./zip.png";

const ClaimCollectionDownloadButton = ({ month }) => {
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadClaimCollection = async () => {
    setIsDownloading(true);
    const res = await documentsAPI.download_claim_collection(
      site_id,
      token,
      month
    );
    fileDownload(res.data, `${month}.zip`, "application/zip");
    setIsDownloading(false);
  };

  return (
    <button
      className={styles.ClaimDownloadButton}
      disabled={isDownloading}
      onClick={downloadClaimCollection}
    >
      {isDownloading ? "Downloading..." : "Download >"}
    </button>
  );
};
export const DocumentsPage = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.documents.status);
  const agree_date = useSelector((state) => state.documents.agree_date);
  const clinical_protocol_link = useProdVar("athelas_clinical_protocol_link");

  const has_baa = useSelector((state) => state.documents.has_baa);
  const has_service_agreement = useSelector(
    (state) => state.documents.has_service_agreement
  );
  const has_contract = useSelector((state) => state.documents.has_contract);

  const claim_collections = useSelector(
    (state) => state.documents.claim_collections
  );

  const claimsDivs = claim_collections.map((claim_collection) => {
    return (
      <div className={styles.ClaimCollectionCard} key={claim_collection.month}>
        <div className={styles.ImageContainer}>
          <img src={ZipPNG} alt="ZIP" className={styles.CollectionIcon} />
        </div>
        <hr className={styles.ClaimCollectionDivider} />
        <div className={styles.ClaimCollectionDate}>
          {IsoDateToFullMonthYYYY(claim_collection.month)}
        </div>
        <div className={styles.NumberOfPatientsSection}>
          <ProfileSVG />
          <span className={styles.NumberOfPatientsNumber}>
            {claim_collection.number_of_patients}
          </span>
          <span>Patients/Claims</span>
        </div>
        <ClaimCollectionDownloadButton month={claim_collection.month} />
      </div>
    );
  });

  const [isDownloadingBAA, setIsDownloadingBAA] = useState(false);
  const [isDownloadingAgreement, setIsDownloadingAgreement] = useState(false);
  const [isDownloadingContract, setIsDownloadingContract] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(sites).length !== 0 && site_id) {
      if (status === "idle") {
        dispatch(agreementStatusThunk({ site_id, token }));
        dispatch(fetchClaimCollectionsThunk({ site_id, token }));
        trackEvent("view", "viewed_documents_page");
      }
    }
  });

  const downloadBAA = async () => {
    setIsDownloadingBAA(true);
    const res = await documentsAPI.download_contracts(site_id, token, "baa");
    fileDownload(res.data, "BAA.pdf");
    setIsDownloadingBAA(false);
  };

  const downloadAgreement = async () => {
    setIsDownloadingAgreement(true);
    const res = await documentsAPI.download_contracts(
      site_id,
      token,
      "agreement"
    );
    fileDownload(res.data, "Service Agreement.pdf");
    setIsDownloadingAgreement(false);
  };

  const downloadContract = async () => {
    setIsDownloadingContract(true);
    const res = await documentsAPI.download_contracts(
      site_id,
      token,
      "contract"
    );
    fileDownload(res.data, "Contract.pdf");
    setIsDownloadingContract(false);
  };

  const isBAADisabled = () => {
    return !has_baa || isDownloadingBAA;
  };

  const isServiceAgreementDisabled = () => {
    return !has_service_agreement || isDownloadingAgreement;
  };

  const isContractDisabled = () => {
    return !has_contract || isDownloadingContract;
  };

  let agreementContent;
  if (has_baa) {
    agreementContent = (
      <div className={styles.AgreementContainer}>
        <div className={styles.RowTab}>
          <span className={styles.DocumentName}>Signed BAA </span>
          <span>({has_baa ? agree_date : "Not Available"})</span>
          <button
            className={
              isBAADisabled()
                ? styles.DisabledDownloadButton
                : styles.DownloadButton
            }
            onClick={downloadBAA}
            disabled={isBAADisabled()}
          >
            {isDownloadingBAA ? "Downloading..." : "Download >"}
          </button>
        </div>
        <div className={styles.RowDivider}></div>
        <div className={styles.RowTab}>
          <span className={styles.DocumentName}>Signed Contract </span>
          <span>({has_service_agreement ? agree_date : "Not Available"})</span>
          <button
            className={
              isServiceAgreementDisabled()
                ? styles.DisabledDownloadButton
                : styles.DownloadButton
            }
            onClick={downloadAgreement}
            disabled={isServiceAgreementDisabled()}
          >
            {isDownloadingAgreement ? "Downloading..." : "Download >"}
          </button>
        </div>
      </div>
    );
  } else if (has_contract) {
    agreementContent = (
      <div className={styles.ContractContainer}>
        <span className={styles.DocumentName}>Signed Contract </span>
        <span>({has_contract ? agree_date : "Not Available"})</span>
        <button
          className={
            isContractDisabled()
              ? styles.DisabledDownloadButton
              : styles.DownloadButton
          }
          onClick={downloadContract}
          disabled={isContractDisabled()}
        >
          {isDownloadingContract ? "Downloading..." : "Download >"}
        </button>
      </div>
    );
  } else {
    agreementContent = (
      <div className={styles.NoAgreementContainer}>
        BAA and agreement are not available at this time, please contact
        support@getathelas.com for copies.
      </div>
    );
  }
  const generalDocuments = (
    <div className={styles.AgreementContainer}>
      <div className={styles.RowTab}>
        <span className={styles.DocumentName}>
          Athelas RPM Clinical Protocol
        </span>
        <a
          href={clinical_protocol_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={styles.DownloadButton}>{"View Document >"}</button>
        </a>
      </div>
      <div className={styles.RowDivider}></div>
      <div className={styles.RowTab}>
        Make edits to your clinical protocol by emailing us at
        support@athelas.com
      </div>
    </div>
  );

  return (
    <>
      <Header title="Documents"></Header>
      <div className={styles.DocumentsContainer}>
        <h1 className={styles.SectionHeader}>Signed Documents</h1>
        {agreementContent}
        <h1 className={styles.SectionHeader}>Claim Collections</h1>
        <div className={styles.ClaimCollectionsContainer}>
          {claimsDivs.length === 0 && "No Claims Collections Yet"}
          {claimsDivs}
        </div>
        <h1 className={styles.SectionHeader}>General Documents</h1>
        {generalDocuments}
      </div>
    </>
  );
};
