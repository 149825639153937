import React, { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import AthelasLogo from "./assets/athelasLogo.png";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { useSelector } from "react-redux";
import patientsAPI from "../patients/patientsAPI";
import moment from "moment";
import { trackEvent } from "../../utils/tracking";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

type PropsType = {
  patientId: number;
  name: Text;
};

export function DownloadPatientReport(props: PropsType) {
  const contentArea = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state: any) => state.user.token);
  const [nurseNotesList, setNurseNotesList] = useState([]);
  const [testResultsList, setTestResultsList] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});
  const [deviceSummaries, setDeviceSummaries] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });

  const formatTestType = (test_type) =>
    test_type.replaceAll("_", " ").toUpperCase();

  const [includeDeviceDiagnosticData, setIncludeDeviceDiagnosticData] =
    useState(false);

  const formatDeviceType = (type) =>
    type?.replace("TestType.", " ").replace("_", " ") || "NONE";

  const formatSignalStrength = (strength) => `${strength}%`;

  const formatBatteryVoltage = (voltage) => {
    const num =
      voltage < 101 ? voltage : Math.round((voltage / 6200) * 1000) / 10;
    return `${num}%`;
  };

  const formatDateRange = () => {
    const format = "MM-DD-YYYY";
    return `${dateRange.startDate.format(format)} to ${dateRange.endDate.format(
      format
    )}`;
  };
  const formatFileNameDate = (date) => {
    return date.format("MM-DD-YYYY");
  };

  const RESULT_ATTRIBUTE_MAP = {
    WEIGHT: ["weight"],
    HEART_RATE: ["bpm"],
    BLOOD_PRESSURE: ["systolic", "diastolic"],
    CARDIAC_CARE: ["cardiac_care_weight_lbs"],
    GLUCOSE: ["glucose_value"],
    PULSE_OXIMETRY: ["spo2"],
  };

  const fetchData = async (fromDate, toDate) => {
    const res = await patientsAPI.getPatientReportForSelectedDateRange(
      props.patientId,
      token,
      fromDate.format("YYYY-MM-DD"),
      toDate.format("YYYY-MM-DD"),
      includeDeviceDiagnosticData
    );
    if (res?.data) {
      let patientData = res?.data?.patient_data;
      setPatientInfo({
        id: patientData.id,
        firstName: patientData.first_name,
        lastName: patientData.last_name,
        gender: patientData.gender,
        dateOfBirth: patientData.date_of_birth,
        age: patientData.age,
        numberOfTestResults: patientData.number_of_test_results,
        numberOfNurseReview: patientData.number_of_nurse_review,
        numberofDevices: patientData.number_of_devices,
        numberOfDevicePings: patientData.number_of_device_pings,
      });
      setDeviceSummaries(patientData.device_summaries);
      setNurseNotesList(patientData.nurse_notes);
      setTestResultsList(patientData.test_results);
    }
    return res;
  };

  const resultsMap = {};
  if (testResultsList && testResultsList.length > 0) {
    testResultsList.map((result) => {
      if (!Object.keys(resultsMap).includes(result.test_type)) {
        resultsMap[result.test_type] = [];
      }
      if (result.test_type !== "UNKNOWN") {
        resultsMap[result.test_type].push(result);
      }
      return null;
    });
  }

  const getDataUnits = (testType) => {
    let value = "";
    switch (testType) {
      case "GLUCOSE":
        value = `g/dL`;
        break;
      case "BLOOD_PRESSURE":
        value = ``;
        break;
      case "WEIGHT":
        value = `pounds`;
        break;
      case "WBC":
        value = latest.wbc_count;
        break;
      case "HEART_RATE":
        value = `bpm`;
        break;
      case "PULSE_OXIMETRY":
        value = `SpO2`;
        break;
      default:
        value = "";
    }
    return value;
  };

  const generateResultsTable = (test_type, data) => {
    if (test_type === "UNKNOWN") {
      return null;
    }
    const formattedTestType = formatTestType(test_type);

    return (
      <>
        <h3 style={{ marginLeft: -2, fontSize: 16 }}>{formattedTestType}</h3>
        <p>
          Test results for the {formattedTestType} RPM program in reverse
          chronological order by test time.
        </p>
        <Table
          bordered
          style={{ width: "100%", fontSize: "10px", border: "solid 1px #888" }}
        >
          <thead>
            <th style={{ textAlign: "left" }}>Test Type</th>
            <th style={{ textAlign: "left" }}>Date</th>
            <th style={{ textAlign: "left" }}>Time</th>
            {RESULT_ATTRIBUTE_MAP[test_type].map((attribute) => {
              return (
                <th style={{ textAlign: "left" }}>
                  {attribute.toUpperCase().replaceAll("_", " ")}
                </th>
              );
            })}
          </thead>
          <tbody>
            {resultsMap[test_type].map((data) => {
              return (
                <tr>
                  <td>{test_type.replaceAll("_", " ").toUpperCase()}</td>
                  <td>{moment(data["test_time"]).format("MMM D, YYYY")}</td>
                  <td>{moment(data["test_time"]).format("hh:mm a")}</td>
                  {RESULT_ATTRIBUTE_MAP[test_type].map((attribute) => {
                    return (
                      <td>
                        {data[attribute]} {getDataUnits(test_type)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <hr />
      </>
    );
  };

  const handleExportWithFunction = async () => {
    setIsLoading(true);
    try {
      await fetchData(dateRange.startDate, dateRange.endDate);
      const formattedStartDate = formatFileNameDate(dateRange.startDate);
      const formattedEndDate = formatFileNameDate(dateRange.endDate);
      const fileName =
        `RPM_${props.name}_Report_From_${formattedStartDate}_to_${formattedEndDate}`.replace(
          /\s+/g,
          "_"
        );

      savePDF(contentArea.current, {
        fileName: fileName,
        paperSize: "A4",
        repeatHeaders: true,
      });
      trackEvent("click", "download_patient_report");
    } catch (error) {
      alert(
        "There was some error while generating report, please contact us at support@getathelas.com"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  return (
    <div>
      <PDFExport ref={null} paperSize="A4">
        <div
          style={{
            background: "white",
            padding: 32,
            borderRadius: 16,
            boxShadow: "0px 0px 10px #dedede",
            minWidth: 400,
          }}
        >
          <h5 style={{ marginLeft: "-8px" }}>Download Patient Report</h5>
          <p>
            Export file contains Nurse Notes, written by Athelas Clinical Staff,
            test results for RPM programs, for {props.name}.
          </p>
          <p>This file can be attached to the EHR entries for this patient.</p>
          <p>
            Please select the date range for which you want to download the
            patient report:
          </p>
          <DateRangePicker
            initialSettings={{
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            }}
            onApply={handleApply}
          >
            <input
              type="text"
              className="form-control"
              value={`${dateRange.startDate.format(
                "MM/DD/YYYY"
              )} - ${dateRange.endDate.format("MM/DD/YYYY")}`}
              readOnly
            />
          </DateRangePicker>

          <div style={{ margin: "20px 0" }}>
            <label>
              <input
                type="checkbox"
                style={{ marginRight: "8px" }}
                checked={includeDeviceDiagnosticData}
                onChange={(e) =>
                  setIncludeDeviceDiagnosticData(e.target.checked)
                }
              />
              Include Device Diagnostic Data
            </label>
          </div>

          <div
            style={{
              width: 300,
              marginTop: 16,
              margin: "0% auto",
              zIndex: 100,
            }}
          >
            <AthelasButton
              variant="info"
              size="small"
              text={isLoading ? "Downloading..." : "Download Patient Report"}
              onClick={handleExportWithFunction}
              disabled={isLoading}
            />
          </div>
        </div>

        {/* PDF Content for Test Results */}
        <div style={{ opacity: 0, height: 0 }}>
          <div
            style={{
              padding: 32,
              fontSize: 10,
            }}
            ref={contentArea}
          >
            <img
              src={AthelasLogo}
              style={{ width: 130 }}
              alt="Athelas Logo"
              key={Date.now()}
            />
            <h2 style={{ marginLeft: -2, fontSize: 18 }}>
              Patient Summary Report from {formatDateRange()}
            </h2>
            <hr></hr>

            <p>
              This document was exported on{" "}
              {moment(Date.now()).format("MMMM D, YYYY")}
            </p>

            <p style={{ margin: "0" }}>Athelas Patient ID: {patientInfo.id}</p>
            <p style={{ margin: "0" }}>
              Name: {patientInfo.firstName} {patientInfo.lastName}
            </p>
            <p style={{ margin: "0" }}>Gender: {patientInfo.gender}</p>
            <p style={{ margin: "0" }}>
              Date of Birth:{" "}
              {moment(patientInfo.dateOfBirth, "YYYY-MM-DD").format(
                "MM-DD-YYYY"
              )}
            </p>
            <p style={{ margin: "0" }}>
              Number of Test Results: {patientInfo.numberOfTestResults}
            </p>
            <p style={{ margin: "0" }}>
              Number of Nurse review: {patientInfo.numberOfNurseReview}
            </p>
            {includeDeviceDiagnosticData && (
              <p style={{ margin: "0" }}>
                Number of Device pings: {patientInfo.numberOfDevicePings}
              </p>
            )}
            <hr />

            {testResultsList && testResultsList.length > 0 && (
              <>
                <h2 style={{ marginLeft: -2, fontSize: 18 }}>Test Results</h2>
                <p>
                  This section contains test results for{" "}
                  {Object.keys(resultsMap).length} different RPM programs. There
                  are a total of {testResultsList.length} different test results
                  recorded during the period from {formatDateRange()}.
                </p>
                {Object.keys(resultsMap).map((test_type, index) => (
                  <div key={index}>
                    {generateResultsTable(test_type, resultsMap[test_type])}
                  </div>
                ))}

                <br></br>
              </>
            )}

            {nurseNotesList && nurseNotesList.length > 0 && (
              <>
                <h2 style={{ marginLeft: -2, fontSize: 18 }}>Nurse Notes</h2>
                <p>
                  This section contains nurse notes written by Athelas Clinical
                  staff. There are a total of {nurseNotesList.length} different
                  nurse notes recorded during the period from{" "}
                  {formatDateRange()}.
                </p>
                <table>
                  {nurseNotesList.map((nurseNote) => {
                    return (
                      <tr>
                        <td
                          style={{
                            width: "40%",
                            padding: 16,
                            border: "solid 1px #888",
                          }}
                        >
                          <p style={{ margin: 0, fontWeight: "bold" }}>
                            Written on:
                          </p>
                          <p style={{ margin: 0 }}>
                            {moment(nurseNote.created_at).format(
                              "MMM D, YYYY - MM:HH A"
                            )}
                          </p>
                          <br />
                          <p style={{ margin: 0, fontWeight: "bold" }}>
                            Review Time:
                          </p>
                          <p style={{ margin: 0 }}>
                            {nurseNote.review_time} minutes
                          </p>
                        </td>
                        <td
                          style={{
                            width: "60%",
                            padding: 16,
                            border: "solid 1px #888",
                          }}
                        >
                          <p style={{ margin: 0, fontWeight: "bold" }}>
                            Priority Notes:
                          </p>
                          <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                            {" "}
                            {nurseNote.escalation_note}
                          </p>
                          <p style={{ margin: 0, fontWeight: "bold" }}>
                            Priority Level:
                          </p>
                          <br />
                          <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                            {nurseNote.escalation_level}
                          </p>
                          <p style={{ margin: 0, fontWeight: "bold" }}>Note:</p>
                          <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                            {nurseNote.notes}
                          </p>
                          <br />
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <br></br>
                <hr></hr>
              </>
            )}
            {deviceSummaries && deviceSummaries.length > 0 && (
              <>
                <h2 style={{ marginLeft: -2, fontSize: 18 }}>Device Pings</h2>
                <p>
                  This section contains device pings received from{" "}
                  {patientInfo.numberofDevices} different devices, recorded
                  during the period from {formatDateRange()}.
                </p>
                <Table
                  bordered
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    border: "solid 1px black",
                    tableLayout: "fixed",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left", width: "15%" }}>
                        Device Type
                      </th>
                      <th style={{ textAlign: "left", width: "20%" }}>
                        Device ID
                      </th>
                      <th style={{ textAlign: "left", width: "20%" }}>Date</th>
                      <th style={{ textAlign: "left", width: "15%" }}>Time</th>
                      <th style={{ textAlign: "left", width: "10%" }}>
                        Signal Strength
                      </th>
                      <th style={{ textAlign: "left", width: "10%" }}>
                        Battery Level
                      </th>
                      <th style={{ textAlign: "left", width: "10%" }}>
                        # of Tests
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deviceSummaries.map((deviceSummary, index) => (
                      <tr key={index}>
                        <td>{formatDeviceType(deviceSummary.device_type)}</td>
                        <td>{deviceSummary.device_id}</td>
                        <td>
                          {moment(deviceSummary.created_at).format(
                            "MMM D, YYYY"
                          )}
                        </td>
                        <td>
                          {moment(deviceSummary.created_at).format("hh:mm a")}
                        </td>
                        <td>
                          {formatSignalStrength(deviceSummary.signal_strength)}
                        </td>
                        <td>
                          {formatBatteryVoltage(deviceSummary.battery_voltage)}
                        </td>
                        <td>{deviceSummary.occurrences}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </div>
      </PDFExport>
    </div>
  );
}
