import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { useSelector } from "react-redux";
import { UserType } from "../user/types";
import { validateFields } from "./AddPatientsValidator";
import patientsAPI from "./patientsAPI";
import { INSURANCE_LIST } from "./InsuranceList";

import jwtDecode from "jwt-decode";
import sitesAPI from "../home/sitesAPI";

type PropsType = {
  show: boolean;
  onHide: () => void;
  onAdd: () => void;
};

export function AddPatientModal(props: PropsType) {
  const token = useSelector((state) => state.user.token);
  const userData: UserType = jwtDecode(token);
  const site_id = useSelector((state: any) => state.user.currSiteId);
  const [focused, setFocused] = useState(false);
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const [prescriberOptions, setPrescriberOptions] = useState({});
  const [fetchedPrescribers, setFetchedPrescribers] = useState(false);
  if (!fetchedPrescribers) {
    sitesAPI.getSitePrescribers(site_id, token).then((res) => {
      var prescribers = {};
      const p = res.data["prescribers"];
      for (var i = 0; i < p.length; i++) {
        prescribers[p[i].name] = p[i].id;
      }
      setPrescriberOptions(
        Object.keys(prescribers).map((name) => {
          return (
            <option value={prescribers[name]} key={name}>
              {name}
            </option>
          );
        })
      );
    });
    setFetchedPrescribers(true);
  }

  const user_id = userData.sub;
  const [prescriberId, setPrescriberId] = useState(-1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [bloodPressureTest, setBloodPressureTest] = useState(false);
  const [weightTest, setWeightTest] = useState(false);
  const [glucoseTest, setGlucoseTest] = useState(false);
  const [isWeightBlind, setIsWeightBlind] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [gender, setGender] = useState("selected");
  const [condition1, setCondition1] = useState("");
  const [condition2, setCondition2] = useState("");
  const [firstTest, setFirstTest] = useState("selected");

  const [errorMessage, setErrorMessage] = useState("");
  const [showProcessesingScreen, setShowProcessesingScreen] = useState(false);

  const invalidICD10CodeMessage =
    "Invalid ICD10 Code - Please ensure the ICD10 code is 3-7 characters where the first character is an alphabet, second character is a number, and the 3rd to 7th character is an alphabet or number";

  const addPatient = () => {
    setErrorMessage("");
    const fieldsValid = validateFields(
      user_id,
      firstName,
      lastName,
      dob,
      phoneNumber,
      address,
      insuranceCompany,
      insuranceNumber,
      bloodPressureTest,
      weightTest,
      glucoseTest,
      isWeightBlind,
      doctorName,
      gender,
      condition1,
      condition2,
      firstTest,
      prescriberId
    );

    if (!fieldsValid.ok) {
      setErrorMessage("Error: " + fieldsValid.message);
      return;
    }

    setShowSuccessScreen(false);
    setShowErrorScreen(false);
    setShowProcessesingScreen(true);

    patientsAPI
      .addNewPatient(
        site_id,
        token,
        user_id,
        firstName,
        lastName,
        dob,
        phoneNumber,
        address,
        insuranceCompany,
        insuranceNumber,
        bloodPressureTest,
        weightTest,
        glucoseTest,
        isWeightBlind,
        doctorName,
        gender,
        condition1,
        condition2,
        firstTest,
        prescriberId
      )
      .then(() => {
        setTimeout(() => {
          setShowProcessesingScreen(false);
          setShowErrorScreen(false);
          setShowSuccessScreen(true);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response.data);
        if (e.response && e.response.data === invalidICD10CodeMessage) {
          setErrorMessage(
            "ICD-10 Code does not exist, you can search for valid ICD-10 Codes here: https://icd10cmtool.cdc.gov/"
          );
        } else {
          setShowSuccessScreen(false);
          setShowErrorScreen(true);
        }
        setShowProcessesingScreen(false);
      });
  };

  if (showSuccessScreen) {
    return (
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Successfully added {firstName} {lastName} to your site.
        </Modal.Body>

        <Modal.Footer>
          <div class="mr-auto">
            <AthelasButton
              variant="success"
              onClick={() => {
                props.onHide();
                setShowErrorScreen(false);
                setShowProcessesingScreen(false);
                setShowSuccessScreen(false);
                window.location.reload();
              }}
              text="Close"
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  if (showErrorScreen) {
    return (
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          There was an error when processing your request. Please contact
          support@athelas.com for further assistance.
        </Modal.Body>

        <Modal.Footer>
          <div class="mr-auto">
            <AthelasButton
              variant="danger"
              onClick={() => {
                props.onHide();
                setShowErrorScreen(false);
                window.location.reload();
              }}
              text="Close"
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  if (showProcessesingScreen) {
    return (
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Processing your request ...</Modal.Title>
        </Modal.Header>

        <Modal.Body>We are adding a new patient to your site.</Modal.Body>

        <Modal.Footer>
          <div class="mr-auto">
            <AthelasButton
              variant="danger"
              onClick={props.onHide}
              text="Close"
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>Complete this form to add a patient to your site.</Modal.Body>

      <Modal.Body>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Form.Group style={{ width: "100%", margin: 8 }}>
            <Form.Control
              as="select"
              id="prescriberSelect"
              value={prescriberId}
              onChange={(e) => {
                setPrescriberId(e.target.value);
              }}
            >
              <option value="" key="emptySelect" selected>
                Filter by Prescriber
              </option>
              {prescriberOptions}
            </Form.Control>
          </Form.Group>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ margin: 8 }}>
            <p style={{ margin: 0 }}>Date of Birth:</p>
            <SingleDatePicker
              date={dob}
              onDateChange={(date) => setDob(date)}
              onFocusChange={({ focused }) => setFocused(focused)}
              focused={focused}
              id="patient_datepicker"
              isOutsideRange={(day) => moment().diff(day) < 0}
            />
          </div>
          <div style={{ width: "100%", margin: 16, marginTop: 26 }}>
            <input
              type="text"
              placeholder="Patient Phone #"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              style={{
                padding: 8,
                width: "100%",
                margin: 8,
                border: "solid 1px #CCC",
                borderRadius: 4,
              }}
            />
          </div>
          <Form.Group style={{ width: "100%", margin: 8, marginTop: 36 }}>
            <Form.Control
              as="select"
              id="prescriberSelect"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            >
              <option value="">Patient Sex</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input
            type="text"
            placeholder="Patient Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
        </div>
        <div style={{ flexDirection: "row", display: "flex", marginTop: 8 }}>
          <p style={{ marginTop: 8, marginLeft: 10 }}>Insurance:</p>

          <Form.Group style={{ width: "100%", margin: 8 }}>
            <Form.Control
              as="select"
              id="prescriberSelect"
              value={insuranceCompany}
              onChange={(e) => {
                setInsuranceCompany(e.target.value);
              }}
            >
              <option value="">Patient Insurance Company</option>
              {INSURANCE_LIST.map((x) => (
                <option value={x}>{x}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input
            type="text"
            placeholder="Patient Insurance Policy Number"
            value={insuranceNumber}
            onChange={(e) => {
              setInsuranceNumber(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input
            type="text"
            placeholder="ICD 1"
            value={condition1}
            onChange={(e) => {
              setCondition1(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
          <input
            type="text"
            placeholder="ICD 2 (optional)"
            value={condition2}
            onChange={(e) => {
              setCondition2(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
        </div>
        <div style={{ margin: 8 }}>
          <input
            type="checkbox"
            id="bp_cuff"
            name="bp_cuff"
            onChange={(e) => {
              setBloodPressureTest(e.target.checked);
              if (firstTest === "BLOOD_PRESSURE") {
                setFirstTest("selected");
              }
            }}
          />
          <label for="bp_cuff" style={{ marginLeft: 4 }}>
            Prescribe Blood Pressure / Heart Rate Cuff
          </label>
        </div>
        <div style={{ margin: 8 }}>
          <input
            type="checkbox"
            id="weight"
            name="weight"
            onChange={(e) => {
              setWeightTest(e.target.checked);
              if (firstTest === "WEIGHT") {
                setFirstTest("selected");
              }
            }}
          />
          <label for="weight" style={{ marginLeft: 4 }}>
            Prescribe Weight Scale
          </label>
        </div>{" "}
        {weightTest && (
          <div style={{ margin: 8 }}>
            <input
              type="checkbox"
              id="weight_blind"
              name="weight_blind"
              onChange={(e) => setIsWeightBlind(e.target.checked)}
            />
            <label for="weight_blind" style={{ marginLeft: 4 }}>
              If deploying a scale, should the scale be blinded?
            </label>
          </div>
        )}
        <div style={{ margin: 8 }}>
          <input
            type="checkbox"
            id="glucose"
            name="glucose"
            onChange={(e) => {
              setGlucoseTest(e.target.checked);
              if (firstTest === "GLUCOSE") {
                setFirstTest("selected");
              }
            }}
          />
          <label for="glucose" style={{ marginLeft: 4 }}>
            Prescribe Glucose Monitor
          </label>
        </div>
        {(bloodPressureTest || weightTest || glucoseTest) && (
          <Form.Group style={{ width: "95%", margin: 8, marginRight: 16 }}>
            <Form.Control
              as="select"
              id="prescriberSelect"
              value={firstTest}
              onChange={(e) => {
                setFirstTest(e.target.value);
              }}
            >
              <option value="">What device should we deploy first?</option>
              {bloodPressureTest && (
                <option value="BLOOD_PRESSURE">Blood Pressure Cuff</option>
              )}
              {weightTest && <option value="WEIGHT">Weight Scale</option>}
              {glucoseTest && <option value="GLUCOSE">Glucose Monitor</option>}
            </Form.Control>
          </Form.Group>
        )}
        <hr />
        <div style={{ padding: 8 }}>
          <p>
            Type your name below and click Authorize to enroll selected patients
            in the RPM program.
          </p>
          <input
            type="text"
            placeholder="Your Name"
            value={doctorName}
            onChange={(e) => {
              setDoctorName(e.target.value);
            }}
            style={{
              padding: 8,
              width: "100%",
              margin: 8,
              border: "solid 1px #CCC",
              borderRadius: 4,
            }}
          />
        </div>
        {errorMessage.length > 0 && (
          <p
            style={{
              color: "#eb4034",
              margin: 8,
              backgroundColor: "#fad9d7",
              borderRadius: 4,
              padding: 8,
            }}
          >
            {errorMessage}
          </p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div class="mr-auto">
          <AthelasButton
            variant="danger"
            onClick={props.onHide}
            text="Cancel"
          />
        </div>
        <AthelasButton
          variant="success"
          onClick={() => {
            addPatient();
          }}
          text={`Add Patient`}
        />
      </Modal.Footer>
    </Modal>
  );
}
